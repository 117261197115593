const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
const MILLISECONDS_PER_WEEK = MILLISECONDS_PER_DAY * 7;
const dateOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

export function formatMessageTimeAgo(messageDate: Date) {
  const now = new Date();
  const diffInMinutes = Math.floor((now.getTime() - messageDate.getTime()) / (1000 * 60)); // Difference in minutes

  if (diffInMinutes < 60) {
    return `${diffInMinutes}min${diffInMinutes > 1 ? 's' : ''}`;
  } else {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours}hour${diffInHours > 1 ? 's' : ''}`;
  }
}

export function formatMessageTimeStamp(timestamp: number): string {
  if (timestamp < 0) {
    return '';
  }
  let date = new Date(timestamp * 1000);
  return date.toLocaleTimeString('en-GB', dateOptions as Intl.DateTimeFormatOptions);
}

export function getNewMessageTimestamp(): number {
  return Math.floor(new Date().getTime() / 1000);
}

export function calculateWeeksBetweenDates(startDate: Date, endDate: Date) {
  let timeDifferenceInMs = getTimeDifferenceInMs(startDate, endDate);
  let numberOfWeeks = Math.round(timeDifferenceInMs / MILLISECONDS_PER_WEEK);
  return numberOfWeeks;
}

export function calculateDaysBetweenDates(startDate: Date, endDate: Date) {
  let timeDifferenceInMs = getTimeDifferenceInMs(startDate, endDate);
  let numberOfDays = Math.floor(timeDifferenceInMs / MILLISECONDS_PER_DAY);
  return numberOfDays;
}

export function getTimeDifferenceInMs(startDate: Date, endDate: Date) {
  return Math.abs(endDate.getTime() - startDate.getTime());
}

export const formatElapsedTime = (elapsedMiliseconds: number): string => {
  const elapsedSeconds = elapsedMiliseconds / 1000;
  const elapsedMinutes = Math.floor(elapsedSeconds / 60);
  const elapsedHours = Math.floor(elapsedMinutes / 60);
  const elapsedDays = Math.floor(elapsedHours / 24);
  const elapsedWeeks = Math.floor(elapsedDays / 7);
  const elapsedYears = Math.floor(elapsedDays / 365);

  if (elapsedYears > 0) {
    return `${elapsedYears}y ago`;
  } else if (elapsedWeeks > 0) {
    return `${elapsedWeeks}w ago`;
  } else if (elapsedDays > 0) {
    return `${elapsedDays}d ago`;
  } else if (elapsedHours > 0) {
    return `${elapsedHours}h ago`;
  } else if (elapsedMinutes > 0) {
    return `${elapsedMinutes}min ago`;
  } else {
    return `now`;
  }
};
