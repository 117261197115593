import { writable } from 'svelte/store';

type ProjectBoardStore = {
  isProjectBoard: boolean;
};

const projectBoardStore = writable<ProjectBoardStore>({
  isProjectBoard: false,
});

export default {
  subscribe: projectBoardStore.subscribe,
  set: projectBoardStore.set,
};
