import { isSmallScreen } from '$lib/utils/window';
import { writable } from 'svelte/store';

let smallScreen = isSmallScreen();

const symStore = writable({
  isChatMenuOpen: smallScreen ? false : true,
});

export default {
  update: symStore.update,
  subscribe: symStore.subscribe,
  set: symStore.set,
};

export const handleChatMenuOpen = () => {
  symStore.update((data) => {
    data.isChatMenuOpen = !data.isChatMenuOpen;
    return data;
  });
};

export const closeChatMenu = () => {
  symStore.update((data) => {
    data.isChatMenuOpen = false;
    return data;
  });
};
